@font-face {
  font-family: PP Neue Montreal;
  src: url("PPNeueMontreal-Regular.5e0546b4.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Medium.0edc0724.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: PP Neue Montreal;
  src: url("Neue-Montreal-Bold.836b8798.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: Signal Mono;
  src: url("Signal Mono-Regular-Web.b0c13c96.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
}

.done-section-review {
  padding: 3.2rem 0;
}

.done-section-review-title {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-50) / var(--done-line-spacing-55) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--0-5);
  text-align: center;
  text-transform: uppercase;
  padding-top: 3.6rem;
  padding-bottom: 1rem;
}

.done-section-review-inner-review {
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: flex;
  box-shadow: 0 25px 60px #12112714;
}

.done-section-review-inner-review .quote-start svg, .done-section-review-inner-review .quote-end svg {
  width: 2.5rem;
  height: 2.5rem;
}

.done-section-review-inner-review .author {
  flex-direction: column;
  gap: 0;
  min-width: 0;
  display: flex;
}

.done-section-review-inner-review .quote-end {
  align-self: end;
}

.done-section-review-subtitle {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-28) / var(--done-line-spacing-30) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--1-4);
  text-transform: uppercase;
}

.done-section-review-comment {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-25) var(--done-font-family-pp-neue-montreal);
  letter-spacing: var(--done-character-spacing--0-2);
}

.done-section-review-position {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--1-4);
  text-transform: uppercase;
  color: gray;
  white-space: wrap;
}

.done-section-review-company-name {
  font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
  letter-spacing: var(--done-character-spacing--1-4);
  text-transform: uppercase;
}

.done-section-review-source {
  justify-content: space-between;
  align-items: center;
  height: 100%;
  display: flex;
}

.done-section-review-source-bottom {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.done-section-review-source-bottom img {
  max-width: 6.6rem;
  margin: auto 0;
  display: block;
}

.done-section-review-source-bottom .author {
  min-width: 0;
}

.done-section-review-source-content {
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
}

.done-section-review-source-img {
  display: none;
}

.done-section-review-carousel {
  flex-direction: column;
  gap: 2.4rem;
  width: 100%;
  max-width: 100vw;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  display: flex;
  position: relative;
  overflow: hidden;
}

.done-section-review-carousel.blog {
  overflow: visible;
}

.done-section-review-carousel-inner {
  will-change: transform;
  transition: transform .5s ease-in-out;
  display: flex;
}

.done-section-review-carousel-item {
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  min-width: 33.33%;
  max-width: 33.33%;
  min-height: 50rem;
  transition: all .5s ease-in-out;
  display: flex;
  overflow: visible;
  scale: .6;
}

.done-section-review-carousel-item-toofew {
  min-width: 50%;
  max-width: 50%;
  min-height: 39rem;
}

.done-section-review-carousel-item img {
  align-self: last baseline;
  max-width: 30%;
  max-height: 7rem;
}

.done-section-review-carousel-item article {
  background-color: var(--done-color-ffffff);
  width: 130%;
  box-shadow: 0 5px 5px #00000040;
}

.done-section-review-carousel-item.active {
  opacity: 1;
  z-index: 2;
  scale: 1;
}

.done-section-review-carousel-item.active .done-section-review-inner-review {
  border-bottom: .6rem solid var(--done-color-00ff89);
  transition: .5 ease-in-out;
  padding: 3rem;
}

.done-section-review-carousel-bullets {
  justify-content: center;
  gap: .8rem;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  padding: 1rem;
  display: flex;
}

.done-section-review-carousel-bullet {
  cursor: pointer;
  border: 1px solid var(--done-color-333834);
  width: 2rem;
  height: 2rem;
}

.done-section-review-carousel-bullet.active, .done-section-review-carousel-bullet.active:focus {
  background-color: var(--done-color-333834);
  border: 1px solid var(--done-color-333834);
}

.done-section-review-carousel-bullet.active:hover {
  background-color: var(--done-color-333834);
  border: 1px solid var(--done-color-00ff89);
}

.done-section-review-carousel-bullet:hover, .done-section-review-carousel-bullet:focus {
  background-color: var(--done-color-00ff89);
  border: 1px solid var(--done-color-00ff89);
}

.section-review-entreprise .done-section-review {
  background: var(--done-color-333834) 0% 0% no-repeat padding-box;
  color: var(--done-color-eaeaf6);
  fill: var(--done-color-eaeaf6);
}

.section-review-entreprise .done-section-review-carousel {
  color: var(--done-color-333834);
}

.section-review-entreprise .done-section-review-carousel-bullet {
  border: 1px solid var(--done-color-eaeaf6);
}

.section-review-entreprise .done-section-review-carousel-bullet.active, .section-review-entreprise .done-section-review-carousel-bullet.active:focus {
  background-color: var(--done-color-eaeaf6);
  border: 1px solid var(--done-color-eaeaf6);
}

.section-review-entreprise .done-section-review-carousel-bullet.active:hover {
  background-color: var(--done-color-eaeaf6);
  border: 1px solid var(--done-color-00ff89);
}

.section-review-entreprise .done-section-review-carousel-bullet:hover, .section-review-entreprise .done-section-review-carousel-bullet:focus {
  background-color: var(--done-color-00ff89);
  border: 1px solid var(--done-color-00ff89);
}

@media only screen and (width >= 992px) {
  .done-section-review-title {
    padding-top: 1.6rem;
  }
}

@media only screen and (width <= 992px) {
  .done-section-review-title {
    padding-top: 3.6rem;
  }

  .done-section-review-position {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-14) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--1-4);
    text-transform: uppercase;
    color: gray;
  }

  .done-section-review-company-name {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--1-4);
    text-transform: uppercase;
  }

  .done-section-review-source {
    height: 100%;
  }

  .done-section-review-source-bottom {
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .done-section-review-source-bottom img {
    max-width: 6.6rem;
    margin: auto 0;
    display: block;
  }

  .done-section-review-source-bottom .author {
    min-width: 0;
  }

  .done-section-review-source-content {
    width: 100%;
  }

  .done-section-review-source-img {
    display: none;
  }

  .done-section-review-carousel-item {
    min-width: 75%;
    max-width: 75%;
    scale: .9;
  }

  .done-section-review-carousel-item article {
    background-color: var(--done-color-ffffff);
    width: 100%;
    min-height: 0;
    box-shadow: 0 5px 5px #00000040;
  }

  .done-section-review-carousel.blog {
    width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
    position: relative;
    left: 50%;
    right: 50%;
    overflow: hidden;
  }

  .done-section-review-subtitle {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-20) / var(--done-line-spacing-20) var(--done-font-family-signal-mono);
    letter-spacing: var(--done-character-spacing--1-4);
    text-transform: uppercase;
  }

  .done-section-review-comment {
    font: var(--done-font-style-normal) normal var(--done-font-weight-normal) var(--done-font-size-16) / var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
    letter-spacing: var(--done-character-spacing--0-2);
  }
}

@media only screen and (width >= 1480px) {
  .done-section-review-title {
    padding-top: 6.8rem;
  }
}

/*# sourceMappingURL=index.74c760d3.css.map */
