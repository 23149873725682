@use 'base';

.done-section {
  &-review {
    padding: 3.2rem 0;

    &-title {
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-50) / var(--done-line-spacing-55)
        var(--done-font-family-signal-mono);
      letter-spacing: var(--done-character-spacing--0-5);
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 1rem;
      padding-top: 3.6rem;
    }

    &-inner-review {
      height: 100%;
      width: 100%;
      padding: 3rem;
      box-shadow: 0px 25px 60px 0px #12112714;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .quote-start svg,
      .quote-end svg {
        width: 2.5rem;
        height: 2.5rem;
      }

      .author {
        gap: 0;
        display: flex;
        flex-direction: column;
        min-width: 0;
      }

      .quote-end {
        align-self: end;
      }
    }

    &-subtitle {
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-28) / var(--done-line-spacing-30)
        var(--done-font-family-signal-mono);
      letter-spacing: var(--done-character-spacing--1-4);
      text-transform: uppercase;
    }

    &-comment {
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-20) / var(--done-line-spacing-25)
        var(--done-font-family-pp-neue-montreal);
      letter-spacing: var(--done-character-spacing--0-2);
    }

    &-position {
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-16) / var(--done-line-spacing-20)
        var(--done-font-family-signal-mono);
      letter-spacing: var(--done-character-spacing--1-4);
      text-transform: uppercase;
      color: #808080;
      white-space: wrap;
    }

    &-company-name {
      font: var(--done-font-style-normal) normal var(--done-font-weight-normal)
        var(--done-font-size-20) / var(--done-line-spacing-20)
        var(--done-font-family-signal-mono);
      letter-spacing: var(--done-character-spacing--1-4);
      text-transform: uppercase;
    }

    &-source {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;

      &-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        img {
          display: block;
          max-width: 6.6rem;
          margin: auto 0;
        }

        .author {
          min-width: 0;
        }
      }

      &-content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex-grow: 1;
        width: 100%;
        justify-content: space-between;
        height: 100%;
      }

      &-img {
        display: none;
      }
    }

    &-carousel {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: fit-content;
      max-width: 100vw;
      margin: auto;
      display: flex;
      flex-direction: column;
      gap: 2.4rem;

      &.blog {
        overflow: visible;
      }

      &-inner {
        display: flex;
        transition: transform 0.5s ease-in-out;
        will-change: transform;
      }

      &-item {
        min-width: 33.33%;
        max-width: 33.33%;
        min-height: 50rem;
        box-sizing: border-box;
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: 0.5s ease-in-out;
        cursor: pointer;
        scale: 0.6;
        z-index: 1;
        overflow: visible;

        &-toofew {
          min-width: 50%;
          max-width: 50%;
          min-height: 39rem;
        }

        img {
          max-width: 30%;
          max-height: 7rem;
          align-self: last baseline;
        }

        article {
          width: 130%;
          background-color: var(--done-color-ffffff);
          box-shadow: 0px 5px 5px 0px #00000040;
        }

        &.active {
          opacity: 1;
          z-index: 2;
          scale: 1;

          .done-section-review-inner-review {
            border-bottom: 0.6rem solid var(--done-color-00ff89);
            transition: 0.5 ease-in-out;
            padding: 3rem;
          }
        }
      }

      &-bullets {
        display: flex;
        justify-content: center;
        gap: 0.8rem;
        width: fit-content;
        margin: auto;
        padding: 1rem;
      }

      &-bullet {
        width: 2rem;
        height: 2rem;
        cursor: pointer;
        border: 1px solid var(--done-color-333834);

        &.active {
          background-color: var(--done-color-333834);
          border: 1px solid var(--done-color-333834);

          &:focus {
            background-color: var(--done-color-333834);
            border: 1px solid var(--done-color-333834);
          }

          &:hover {
            background-color: var(--done-color-333834);
            border: 1px solid var(--done-color-00ff89);
          }
        }

        &:hover,
        &:focus {
          background-color: var(--done-color-00ff89);
          border: 1px solid var(--done-color-00ff89);
        }
      }
    }
  }
}

.section-review-entreprise {
  .done-section-review {
    background: var(--done-color-333834) 0% 0% no-repeat padding-box;
    color: var(--done-color-eaeaf6);
    fill: var(--done-color-eaeaf6);

    &-carousel {
      color: var(--done-color-333834);

      &-bullet {
        border: 1px solid var(--done-color-eaeaf6);

        &.active {
          background-color: var(--done-color-eaeaf6);
          border: 1px solid var(--done-color-eaeaf6);

          &:focus {
            background-color: var(--done-color-eaeaf6);
            border: 1px solid var(--done-color-eaeaf6);
          }

          &:hover {
            background-color: var(--done-color-eaeaf6);
            border: 1px solid var(--done-color-00ff89);
          }
        }

        &:hover,
        &:focus {
          background-color: var(--done-color-00ff89);
          border: 1px solid var(--done-color-00ff89);
        }
      }
    }
  }
}

@media only screen and (min-width: base.$large-devices) {
  .done-section {
    &-review {
      &-title {
        padding-top: 1.6rem;
      }
    }
  }
}

@media only screen and (max-width: base.$large-devices) {
  .done-section {
    &-review {
      &-title {
        padding-top: 3.6rem;
      }

      &-position {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-14) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--1-4);
        text-transform: uppercase;
        color: #808080;
      }

      &-company-name {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-16) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--1-4);
        text-transform: uppercase;
      }

      &-source {
        height: 100%;
        &-bottom {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          img {
            display: block;
            max-width: 6.6rem;
            margin: auto 0;
          }

          .author {
            min-width: 0;
          }
        }

        &-content {
          width: 100%;
        }

        &-img {
          display: none;
        }
      }

      &-carousel {
        &-item {
          max-width: 75%;
          min-width: 75%;
          scale: 0.9;

          article {
            width: 100%;
            min-height: 0;
            background-color: var(--done-color-ffffff);
            box-shadow: 0px 5px 5px 0px #00000040;
          }
        }

        &.blog {
          overflow: hidden;
          position: relative;
          width: 100vw;
          left: 50%;
          right: 50%;
          margin-left: -50vw;
          margin-right: -50vw;
        }
      }

      &-subtitle {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-20) /
          var(--done-line-spacing-20) var(--done-font-family-signal-mono);
        letter-spacing: var(--done-character-spacing--1-4);
        text-transform: uppercase;
      }

      &-comment {
        font: var(--done-font-style-normal) normal
          var(--done-font-weight-normal) var(--done-font-size-16) /
          var(--done-line-spacing-20) var(--done-font-family-pp-neue-montreal);
        letter-spacing: var(--done-character-spacing--0-2);
      }
    }
  }
}

@media only screen and (min-width: base.$xx-large-devices) {
  .done-section {
    &-review {
      &-title {
        padding-top: 6.8rem;
      }
    }
  }
}
